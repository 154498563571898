import React from 'react';
import { Grid, Image, Box, Anchor, Text } from 'grommet';
import styled from 'styled-components';

const StyledAnchor = styled(Anchor)`
  box-shadow: none;
  transition: box-shadow 0.2s;
  display: flex;
  justify-content: center;

  &:hover {
    box-shadow: 0px 8px 32px rgba(27, 28, 29, 0.1);
  }
`;

const AppBox = ({ title, link, image }) => {
  return (
    <StyledAnchor href={link} target="_blank" style={{ fontWeight: 400, textDecoration: 'none', color: '#1B1C1D' }}>
      <Box alignSelf="center" align="center" justify="center" width="xsmall" pad={{ top: 'xsmall', right: 'small', bottom: 'small', left: 'small' }} hoverIndicator>
        <Image width="67" height="67" src={image} />
        <Text size="small" margin={{ top: 'xsmall' }}>
          {title}
        </Text>
      </Box>
    </StyledAnchor>
  );
};

const M365Apps = ({ metadata, data }) => {
  return (
    <Grid columns="xsmall" fill="horizontal" gap="small">
      {data.apps.map((app) => (
        <AppBox key={app.title} title={app.title} link={app.link} image={app.image}></AppBox>
      ))}
    </Grid>
  );
};

export default M365Apps;
