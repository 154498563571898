import React from 'react';
import { Text, Grid, Box, CardFooter, Anchor } from 'grommet';

import { DateTime, Interval } from 'luxon';

const MarkYourCalendar = ({ metadata, data }) => {
  const today = DateTime.now().plus({ months: 1 }); // extra month added for testing - remove on deploy!
  //const today = DateTime.now();

  const calendar = data.FiscalDates?.map((quarter) => {
    const startDate = DateTime.fromISO(quarter.startDate);
    const endDate = DateTime.fromISO(quarter.endDate).plus({ days: 1 });
    const timeSpan = Interval.fromDateTimes(startDate, endDate);

    return {
      quarter: quarter.quarter,
      startDate: startDate,
      endDate: endDate,
      isCurrent: timeSpan.contains(today),
    };
  });

  let currentQuarter;
  if (calendar) {
    currentQuarter = calendar.find((obj) => {
      return obj.isCurrent == true;
    });
  }

  const daysRemaining = currentQuarter ? Math.ceil(currentQuarter.endDate.diff(today, ['days']).get('days')) : false;

  return (
    (data.Events && (
      <Box>
        <Box height={{ min: 'auto' }} direction="row" justify={daysRemaining ? 'between' : 'end'} align="start" pad={{ left: 'xsmall' }}>
          {daysRemaining && (
            <Box direction="row" align="center">
              <Text size="24px" color="green">
                {daysRemaining}
              </Text>
              <Text size="medium" margin={{ horizontal: 'xsmall' }}>
                {' '}
                Days left in{' '}
              </Text>
              <Text size="medium" color="blue">
                Q{currentQuarter.quarter} FY'{data.FinancialYear.slice(2)}
              </Text>
            </Box>
          )}
          <Anchor
            href={data.RelatedLinks.find((link) => link.linkTitle == 'Fiscal Calendar').linkUrl}
            style={{ fontWeight: 400, textDecoration: 'none', display: 'flex', alignItems: 'flex-end' }}
            target="_blank"
          >
            <svg width="16" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.6708 6.27l-.004.0001c-.0102-.297-.0741-.4462-.1158-.5042-.022.0189-.0562.0568-.0925.13-.0612.124-.1112.3207-.1023.5912.019.4528.0847.793.187 1.0775.1-.4.1514-.8216.1277-1.2946zm.7373-.1657c-.027-.319-.1096-.5898-.2568-.788-.1814-.2443-.4662-.3734-.7704-.292-.2723.073-.4725.2921-.5947.5392-.126.255-.192.5795-.1796.9502l.0001.0032c.0345.8244.2098 1.3832.5061 1.8828a3.882 3.882 0 00.0906.1453c-.101.2378-.212.4768-.327.724-.0636.1372-.1286.2768-.1936.4202-.1318.2915-.3293.6983-.5602 1.134-.8523.3061-1.569.7545-2.1784 1.4393-.2294.2548-.3763.5213-.426.781-.0499.2615-.0014.539.1945.7381.1967.2.473.2509.7308.2007.2547-.0497.5097-.1974.7442-.4233.4079-.3831.8552-1.0678 1.2377-1.7323.074-.1286.1466-.2582.217-.3868.4038-.1256.85-.2238 1.3464-.3036.345-.053.7763-.1032 1.2297-.1281.1557.1646.306.3135.4515.4467.4386.4016.8616.6863 1.2785.7992l.001.0003c.5827.1558 1.177.0203 1.4381-.4065.1385-.2264.1524-.5019.0229-.7521-.1222-.2361-.3523-.4157-.6417-.5413-.5561-.2413-1.291-.3125-1.9804-.3125-.0842 0-.1686.0011-.2526.0031-.2341-.2618-.4854-.5634-.7558-.906l-.0006-.0008a28.5308 28.5308 0 00-.39-.4783c-.1244-.1503-.2362-.2853-.3372-.4162.2543-.6858.4264-1.4385.3598-2.3397l-.004.0002zm-.6862 3.126c-.0556.122-.112.2427-.1681.3627-.0635.1358-.1266.2707-.1877.4056l.3558-.7682zm0 0c.0429.0522.0863.1047.1303.1578.111.1342.225.272.3395.4161.1386.1757.2736.3424.405.5001a13.6415 13.6415 0 00-.7232.0909l-.0025.0004a12.4994 12.4994 0 00-.7619.1453c.1-.203.187-.3877.257-.5423l.3558-.7682zm2.5733 1.7772c.349.3022.6277.4625.8488.5225.1926.0515.3447.0442.4479.0161.1069-.0292.147-.0751.1558-.0895l.0012-.0011c.0015-.001.0024-.0016-.0045-.015-.016-.031-.08-.1137-.2742-.1979-.3033-.1317-.7142-.2055-1.175-.2351zm-4.8188.9437c-.3625.219-.6829.485-.9726.8106l-.0016.0018c-.1676.1861-.2304.3327-.2472.4207-.0063.0327-.006.0537-.0044.0662a.1921.1921 0 00.0493-.0044c.0756-.0147.2063-.0717.3688-.2286l.004-.0038c.2363-.2215.5177-.6066.8037-1.0625z"
                fill="#D2231A"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.1875.9C1.4626.9.875 1.5044.875 2.25v13.5c0 .7456.5876 1.35 1.3125 1.35h9.625c.7248 0 1.3125-.6044 1.3125-1.35V5.7728a.4568.4568 0 00-.1281-.3182l-4.3-4.4228A.4314.4314 0 008.3876.9H2.1875zm0-.9C.9794 0 0 1.0074 0 2.25v13.5C0 16.9926.9794 18 2.1875 18h9.625C13.0206 18 14 16.9926 14 15.75V5.7728c0-.358-.1383-.7014-.3844-.9546l-4.3-4.4228C9.0695.1422 8.7356 0 8.3876 0H2.1875z"
                fill="#1B1C1D"
              />
            </svg>
            <Text color="blue" size="xsmall" margin={{ left: 'xsmall' }}>
              Fiscal Calendar
            </Text>
          </Anchor>
        </Box>
        <Box height={{ min: 'auto' }} pad={{ vertical: 'small', horizontal: 'none' }}>
          {data.Events.map((event, index) => {
            const dateObj = DateTime.fromISO(event.startDate);
            const startDay = dateObj.day;
            const startMonth = dateObj.monthShort;
            const lightness = 35 * (1.5 - index / data.Events.length);
            const bgColor = `hsl(192deg 100% ${lightness}%)`;

            return (
              <Grid columns={['60px', 'flex']} align="center" key={index}>
                <Box direction="column" background={bgColor} fill="vertical" pad="xsmall" align="center" justify="center" style={{ aspectRatio: '1/1' }}>
                  <Anchor href={event.eventUrl} target="_blank" style={{ fontWeight: 400, textDecoration: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Text size="large" color="white">
                      {startDay}
                    </Text>
                    <Text size="xsmall" color="white" style={{ lineHeight: '10px' }}>
                      {startMonth.toUpperCase()}
                    </Text>
                  </Anchor>
                </Box>
                <Box
                  fill="vertical"
                  justify="center"
                  pad={{ top: 'xsmall', bottom: 'xsmall', left: 'small', right: 'xsmall' }}
                  border={
                    index == data.Events.length - 1
                      ? [
                          {
                            color: 'light-4',
                            side: 'top',
                          },
                          {
                            color: 'light-4',
                            side: 'bottom',
                          },
                        ]
                      : [
                          {
                            color: 'light-4',
                            side: 'top',
                          },
                        ]
                  }
                >
                  <Anchor href={event.eventUrl} target="_blank" fill="vertical" style={{ fontWeight: 400, textDecoration: 'none' }}>
                    <Text size="small" color="blue">
                      {event.eventTitle}
                    </Text>
                  </Anchor>
                </Box>
              </Grid>
            );
          })}
        </Box>

        <CardFooter align="start" gap="medium" justify="start" pad={{ horizontal: 'none', top: 'small', bottom: 'none' }} margin={{ bottom: 'small' }}>
          {data.RelatedLinks.map((link) => {
            if (link.linkTitle == 'Fiscal Calendar') return;
            return <Anchor key={link.linkTitle} href={link.linkUrl} label={link.linkTitle} target="_blank" size="medium" color="blue" style={{ textDecoration: 'none' }}></Anchor>;
          })}
        </CardFooter>
      </Box>
    )) ||
    null
  );
};

export default MarkYourCalendar;
