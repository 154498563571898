import { Box, Text } from 'grommet';
import { DateTime } from 'luxon';
import React from 'react';

import Event from './event';
import parseEvents from './helpers';

const CiscoEvents = ({ metadata, data }) => {
  // const [now, setCurrentTime] = useState(DateTime.now());

  // useEffect(() => {
  //   // Update the current time value every second
  //   if (!data.items) return;
  //   const tick = setInterval(() => {
  //     const now = DateTime.now().toUTC();
  //     setCurrentTime(now);
  //   }, 1000);
  //   return () => clearInterval(tick);
  // }, [data]);

  const timeZone = data.timezone;
  const now = DateTime.now().toUTC();

  if (!data.items) return;

  const eventData = data.skipProcessing ? data.items : parseEvents(data.items, now, timeZone);
  const pastCount = eventData.pastCount || 0;
  const allDayCount = eventData.allDayCount || 0;
  const events = eventData.events || eventData;

  return (
    <Box>
      <Box align="baseline" justify="start" direction="row" pad={{ bottom: 'medium', horizontal: 'small' }} border={{ side: 'bottom', color: 'background-back' }}>
        <Text color="green" size="large">
          {DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)}
        </Text>
        <Text size="large" margin={{ left: 'small' }}>
          {DateTime.now().toLocaleString({ weekday: 'long', day: 'numeric', month: 'long' })}
        </Text>
        <Text margin={{ left: 'small' }} size="xsmall">
          ({events.length - pastCount} event{events.length - pastCount > 1 || events.length == 0 ? 's' : ''})
        </Text>
      </Box>
      <Box pad={{ horizontal: 'small', top: 'medium' }}>
        {events.length &&
          events.map((event) => {
            if (event.isPast == true) return null;
            return <Event key={event.id} data={event} />;
          })}
      </Box>
    </Box>
  );
};

export default CiscoEvents;
