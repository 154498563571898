import { Anchor, Avatar, Box, Image, Text } from 'grommet';
import React from 'react';

import achiles from './achiles.jpeg';
import email from './email.svg';
import jgembala from './jgembala.jpeg';
import marfowle from './marfowle.jpeg';
import phone from './phone.svg';
import smrkumar from './smrkumar.jpeg';
import webex from './webex.svg';

const avatars = {
  achiles,
  jgembala,
  marfowle,
  smrkumar,
};

const openLink = (href) => window.open(href);

const TeamMember = ({ item, hasBorder }) => (
  <Box flex direction="row" fill gap="small" align="center" responsive={false} margin={item.ismgr ? { bottom: 'small' } : { top: 'small' }}>
    <Avatar
      src={avatars[item.uid]}
      onClick={() => openLink(`https://directory.cisco.com/dir/reports/${item.uid}`)}
      size={item.ismgr ? 'large' : 'medium'}
      margin={item.ismgr ? {} : { left: 'small', right: 'small' }}
    />
    <Box flex direction="column" fill="vertical" justify="center" onClick={() => openLink(`https://directory.cisco.com/dir/reports/${item.uid}`)}>
      {item.ismgr && <Text size="large">Your Manager</Text>}
      <Text>{`${item.name} (${item.uid})`}</Text>
      <Text size="small" truncate>
        {item.title}
      </Text>
    </Box>
    <Anchor href={`ciscospark://im?email=${item.uid}@cisco.com`}>
      <Box height="xxsmall" width="xxxsmall" justify="center">
        <Image src={webex} />
      </Box>
    </Anchor>
    <Anchor href={`mailto:${item.uid}@cisco.com`}>
      <Box height="xxsmall" width="xxxsmall" justify="center">
        <Image src={email} />
      </Box>
    </Anchor>
    {item.prfph && (
      <Anchor href={`tel:${item.prfph}`}>
        <Box height="xxsmall" width="xxxsmall" justify="center">
          <Image src={phone} />
        </Box>
      </Anchor>
    )}
  </Box>
);

const YourTeam = ({ metadata, data }) => (
  <Box flex direction="column" margin="small">
    {data.items.map((item, index) => (
      <div key={item.uid}>
        <TeamMember item={item} />
        {index !== data.items.length - 1 && <Box border={[{ side: 'top', size: '1px', style: 'solid', color: '#00000020' }]} />}
      </div>
    ))}
  </Box>
);

export default YourTeam;
