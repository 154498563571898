import React from 'react';
import { Box, Grid, Anchor, Text, Paragraph, Stack, Button, Carousel, Image } from 'grommet';
import { FormPrevious, FormNext, List } from 'grommet-icons';
import styled from 'styled-components';

const YoutubeEmbed = ({ className, embedId, title }) => (
  <div className={className}>
    <iframe
      src={`https://www.youtube.com/embed/${embedId}?modestbranding=1&rel=0`}
      frameBorder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={`${title}`}
    />
  </div>
);

const StyledYoutubeEmbed = styled(YoutubeEmbed)`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  min-height: 250px;

  &::before {
    display: block;
    content: '';
    padding-top: 56.5%;
  }

  > iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const CarouselButton = styled(Button)`
  all: unset;
  position: absolute;
  top: calc(50% - 21px);
  transform: translatey(-50%);
  height: 42px;
  width: 42px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.17);
  background: #fff;
  border-radius: 50%;
  transition: background 0.2s;
  font-size: 24px;
  text-align: center;

  &:hover {
    background: #f4f5f6;
  }
`;

const PlaylistOverlay = styled(Box)`
  background: rgba(0, 0, 0, 0.65);
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 5px);
  width: 40%;
  align-items: center;
  justify-content: center;
`;

const Slide0 = ({ playlists }) => {
  const playlistsToShow = playlists.slice(0, 2);
  return (
    <Grid
      columns={{
        count: 2,
        size: 'auto',
      }}
      gap="small"
    >
      {playlistsToShow.map((playlist) => (
        <Box key={playlist.id}>
          <Anchor href={playlist.link} target="_blank" style={{ zIndex: '1', textDecoration: 'none' }}>
            <Stack>
              <Image key={playlist.id} src={playlist.thumbnail} fit="contain" fill="horizontal" style={{ minHeight: 150 }} />
              <PlaylistOverlay>
                <Text size="small">{playlist.count}</Text>
                <List color="light-1" />
              </PlaylistOverlay>
            </Stack>
          </Anchor>
          <Anchor href={playlist.link} target="_blank" style={{ zIndex: '1', textDecoration: 'none' }}>
            <Paragraph margin={{ top: 'small', bottom: 'xxsmall', horizontal: 'none' }}>{playlist.title}</Paragraph>
          </Anchor>
          <Anchor href="https://www.youtube.com/c/CiscoSystems" target="_blank" style={{ zIndex: '1', textDecoration: 'none' }}>
            <Text size="xsmall" color="dark-3">
              Cisco
            </Text>
          </Anchor>
        </Box>
      ))}
    </Grid>
  );
};
const Slide1 = ({ playlists }) => {
  const playlistsToShow = playlists.slice(2);
  return (
    <Grid
      columns={{
        count: 2,
        size: 'auto',
      }}
      gap="small"
    >
      {playlistsToShow.map((playlist) => (
        <Box key={playlist.id}>
          <Anchor href={playlist.link} target="_blank" style={{ zIndex: '1', textDecoration: 'none' }}>
            <Stack>
              <Image key={playlist.id} src={playlist.thumbnail} fit="contain" fill="horizontal" style={{ minHeight: 150 }} />
              <PlaylistOverlay>
                <Text size="small">{playlist.count}</Text>
                <List color="light-1" />
              </PlaylistOverlay>
            </Stack>
          </Anchor>
          <Anchor href={playlist.link} target="_blank" style={{ zIndex: '1', textDecoration: 'none' }}>
            <Paragraph margin={{ top: 'small', bottom: 'xxsmall', horizontal: 'none' }}>{playlist.title}</Paragraph>
          </Anchor>
          <Anchor href="https://www.youtube.com/c/CiscoSystems" target="_blank" style={{ zIndex: '1', textDecoration: 'none' }}>
            <Text size="xsmall" color="dark-3">
              Cisco
            </Text>
          </Anchor>
        </Box>
      ))}
    </Grid>
  );
};

const CiscoYoutube = ({ metadata, data }) => {
  const [activeSlide, setActiveSlide] = React.useState(0);

  return (
    <Box>
      <StyledYoutubeEmbed embedId={data.video.id} title={data.video.title}></StyledYoutubeEmbed>
      <Box pad={{ vertical: 'small', horizontal: 'none' }} style={{ position: 'relative' }}>
        <Carousel margin={activeSlide != 0 ? { left: 'medium' } : { right: 'medium' }} controls={false} activeChild={activeSlide} onChild={setActiveSlide}>
          <Slide0 playlists={data.playlists} />
          <Slide1 playlists={data.playlists} />
        </Carousel>
        {activeSlide != 0 && (
          <CarouselButton as="button" style={{ left: '0', zIndex: '2' }} onClick={() => setActiveSlide(activeSlide - 1)}>
            <FormPrevious />
          </CarouselButton>
        )}
        {activeSlide != 1 && (
          <CarouselButton as="button" style={{ right: '0', zIndex: '2' }} onClick={() => setActiveSlide(activeSlide + 1)}>
            <FormNext />
          </CarouselButton>
        )}
      </Box>
    </Box>
  );
};

export default CiscoYoutube;
