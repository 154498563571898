import { Anchor, Box, Image, Text } from 'grommet';
import React from 'react';

import story1 from './story1.jpeg';
import story2 from './story2.jpeg';
import story3 from './story3.jpeg';
import story4 from './story4.jpeg';
import story5 from './story5.jpeg';
import story6 from './story6.jpeg';
import story7 from './story7.jpeg';
import story8 from './story8.jpeg';

const thumbnails = {
  121408: story1,
  121405: story2,
  121407: story3,
  121404: story4,
  121401: story5,
  121402: story6,
  121403: story7,
  121397: story8,
};

const NewsItem = ({ item }) => (
  <Box fill="horizontal" height="xsmall" align="center" flex="grow" direction="row" overflow="hidden">
    <Box align="center" justify="center" width="small" margin="small" fill="vertical">
      <Anchor href={item.versions['1 - EN - A_DEF'].linkUrl} target="_blank">
        <Image fit="contain" fill="vertical" src={thumbnails[item.id]} />
      </Anchor>
    </Box>
    <Box align="start" justify="start" direction="column" fill="horizontal">
      <Text size="xsmall" color="dark-1">
        {item.dateDisplay}
      </Text>
      <Anchor
        href={item.versions['1 - EN - A_DEF'].linkUrl}
        size="small"
        color="blue"
        target="_blank"
        style={{ width: '100%', textDecoration: 'none', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        <Text truncate size="small">
          {item.versions['1 - EN - A_DEF'].title}
        </Text>
      </Anchor>
      <Text size="xsmall" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {item.versions['1 - EN - A_DEF'].description}
      </Text>
    </Box>
  </Box>
);

const News = ({ metadata, data }) => (
  <Box flex direction="column" gap="small">
    {data.items.map((item) => (
      <NewsItem key={item.id} item={item} />
    ))}
  </Box>
);

export default News;
