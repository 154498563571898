import { Anchor, Avatar, Box, Button, Text, Tip } from 'grommet';
import { StatusGood } from 'grommet-icons';
import React, { useState } from 'react';

import jnickola from './jnickola.jpg';

const avatars = {
  jnickola,
};

const PersonalRoom = ({ metadata, data }) => {
  const [isCopied, setIsCopied] = useState(false);

  const link = `https://cisco.webex.com/join/${data.uid}`;

  const copyText = () => {
    navigator.clipboard.writeText(link);
    setIsCopied(true);
  };

  return (
    <Box direction="row" align="center" gap="large">
      <Avatar src={avatars[data.uid]} size="xlarge" margin={{left: 'small'}} />
      <Box direction="column" justify="center" align="center" gap="small">
        <Button
          primary         
          target="_blank"
          href={link}
          label="Start a Meeting"
          style={{ borderRadius: '25px', background: 'green', color: 'white', fontWeight: '400', padding: '10px 25px' }}
        ></Button>
        {isCopied ? (
          <Box gap="xsmall" direction="row" fill="vertical">
            <StatusGood color="green"></StatusGood>
            <Text>Link copied to clipboard</Text>
          </Box>
        ) : (
          <Tip
            content={
              <Box pad="xxsmall" align="center" justify="center">
                <Text size="small">Copy to clipboard</Text>
              </Box>
            }
          >
            <Anchor color="blue" label="Copy Personal Room Link" size="small" onClick={() => copyText()} style={{ textDecoration: 'none' }}></Anchor>
          </Tip>
        )}
      </Box>
    </Box>
  );
};

export default PersonalRoom;
