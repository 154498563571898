import { Anchor, Avatar, Box, Image, Text } from 'grommet';
import { DateTime } from 'luxon';
import React from 'react';

const Tweet = ({ content, avatar, accountName, attachment, date, metrics }) => {
  // calculate relative timestamp
  const dateObj = DateTime.fromISO(date);
  const secondsDiff = Math.abs(dateObj.diffNow('seconds').toObject().seconds);
  let ago = dateObj.toRelative({
    style: 'narrow',
  });

  if (secondsDiff < 86400) {
    // unit is in seconds, hrs or mins
    ago = ago.slice(0, -5);
  } else {
    // just show the date
    ago = dateObj.toLocaleString({ month: 'short', day: 'numeric' });
  }

  return (
    <Box align="stretch" justify="start" direction="row" height={{ min: 'auto' }} margin={{ bottom: 'large' }}>
      <Anchor src="https://twitter.com/Cisco" target="_blank">
        <Avatar align="center" flex={false} justify="center" overflow="hidden" round="full" src={avatar} />
      </Anchor>
      <Box align="start" justify="center" margin={{ left: 'small' }}>
        <Anchor src="https://twitter.com/Cisco" target="_blank" style={{ fontWeight: 400, textDecoration: 'none' }} margin={{ bottom: 'xsmall' }} color="text">
          <Text weight="bold" size="small">
            {accountName}
          </Text>
          <Text size="small" color="text-weak" margin={{ left: 'xsmall' }}>
            @{accountName.toLowerCase()} {ago}
          </Text>
        </Anchor>
        <Text size="medium" color="text" dangerouslySetInnerHTML={{ __html: content }}></Text>
        {metrics?.like_count > 0 && metrics?.retweet_count > 0 && (
          <Text size="small" color="text-weak" margin={{ top: 'xsmall' }}>
            {metrics.like_count} Likes&nbsp;&nbsp;{metrics.retweet_count} Retweets
          </Text>
        )}
        {attachment && (
          <Box align="center" justify="center" border={{ color: 'background-back' }} round="small" overflow="hidden" margin={{ top: 'small' }}>
            <Image src={attachment} fit="contain" fill="horizontal" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const CiscoTwitter = ({ metadata, data }) => {
  data.items.forEach((tweet) => {
    let formattedContent = tweet.title;
    let attachment,
      attachmentObj = '';

    if (tweet.attachments?.media_keys?.length > 0) {
      // get attachment from media object
      attachmentObj = tweet.media.find((obj) => {
        return obj.media_key == tweet.attachments.media_keys[0];
      });
      attachment = attachmentObj.url ? attachmentObj.url : attachmentObj.preview_image_url;
    }

    if (tweet.entities) {
      tweet.entities.forEach((entity) => {
        let replacementString;
        let regex;

        switch (entity.type) {
          case 'hashtag':
            replacementString = `<a href="https://twitter.com/hashtag/${entity.tag}" target="_blank">#${entity.tag}</a>`;
            regex = new RegExp(`\#${entity.tag}`, 'g');
            formattedContent = formattedContent.replaceAll(regex, replacementString);
            break;
          case 'mention':
            replacementString = `<a href="https://twitter.com/${entity.username}" target="_blank">@${entity.username}</a>`;
            regex = new RegExp(`\@${entity.username}`, 'g');
            formattedContent = formattedContent.replaceAll(regex, replacementString);
            break;
          case 'url':
            replacementString = `<a href="${entity.url}" target="_blank">${entity.url}</a>`;
            regex = new RegExp(`"[^"]+"|(${entity.url})`, 'g');
            formattedContent = formattedContent.replaceAll(regex, (m, group1) => {
              if (!group1) return m;
              else return replacementString;
            });

            if (entity.images) attachment = entity.images[0].url;
            break;
        }
      });

      tweet.formattedContent = formattedContent;
      if (attachment) tweet.attachment = attachment;
    }
  });
  return (
    <Box>
      {data.items.map((tweet) => (
        <Tweet
          key={tweet.id}
          content={tweet.formattedContent ? tweet.formattedContent : tweet.title}
          avatar={tweet.thumbnail}
          accountName={tweet.accountName}
          attachment={tweet.attachment}
          date={tweet.date}
          metrics={tweet.metrics}
        ></Tweet>
      ))}
    </Box>
  );
};

export default CiscoTwitter;
