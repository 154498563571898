import { Anchor, Avatar, Box, Button, Grid, Layer, Text } from 'grommet';
import { Sync } from 'grommet-icons';
import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import achiles from '../your-team/achiles.jpeg';
import jgembala from '../your-team/jgembala.jpeg';
import marfowle from '../your-team/marfowle.jpeg';
import smrkumar from '../your-team/smrkumar.jpeg';
import EventDetail from './event-detail';

const avatars = {
  achiles,
  jgembala,
  marfowle,
  smrkumar,
};

const HostAvatar = styled(Avatar)`
  box-shadow: 0 0 0 1px #fff, 0 0 0 2.5px var(--at-link);
`;

const Event = ({ data }) => {
  const [open, setOpen] = React.useState();
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(undefined);

  return (
    <Box align="center" justify="center" height={{ min: 'auto' }}>
      <Grid columns={['xsmall', '2px', 'flex']} fill="horizontal" gap={{ row: 'none', column: 'medium' }}>
        <Box align="end" justify="start" direction="column">
          <Text size="xsmall" weight="bold" color="dark-3" textAlign="end">
            {DateTime.fromISO(data.startDate).toRelative()}
          </Text>
        </Box>
        <Box align="center" justify="start" width="xxsmall" background={{ color: 'background-back' }} direction="column" overflow="visible" pad={{ top: '8px' }}>
          <Box align="center" justify="center" position="absolute" round="full" width="8px" height="8px" background={{ color: 'blue' }} style={{ maxWidth: 'unset' }} />
        </Box>
        <Box align="start" justify="center">
          {!data.isAllDay && data.startDate && (
            <Text size="xsmall" weight="bold" color="blue" margin={{ bottom: 'xsmall' }}>
              {DateTime.fromISO(data.startDate).toLocaleString(DateTime.TIME_SIMPLE)} &ndash; {DateTime.fromISO(data.endDate).toLocaleString(DateTime.TIME_SIMPLE)}
            </Text>
          )}
          {data.isAllDay && (
            <Text size="xsmall" weight="bold" color="blue" margin={{ bottom: 'xsmall' }}>
              All Day
            </Text>
          )}
          <Box direction="row" align="flex-end">
            <Text size="small" weight="bold">
              {data.title}
            </Text>
            {data.isRecurring && (
              <Box style={{ display: 'inline-flex' }} direction="row" align="center" margin={{ left: 'small' }}>
                <Sync size="14px" color="text-weak" />
                <Text margin={{ left: 'xsmall' }} size="xsmall" color="text-weak">
                  {' '}
                  Series
                </Text>
              </Box>
            )}
          </Box>
          <Box align="start" justify="start" direction="row" pad={{ top: 'small', bottom: 'medium' }}>
            <Box align="center">
              <HostAvatar src={avatars[data.organizer.avatar]} size="30px" />
              <Text size="xsmall" margin={{ top: 'xsmall' }}>
                Host
              </Text>
            </Box>
            <Box direction="row" align="center">
              {data.attendees?.map((attendee, index) => {
                if (index > 4) return null;
                return <Avatar key={attendee.name} src={avatars[attendee.avatar]} size="30px" margin={{ left: 'small' }} />;
              })}
              {data.attendees?.length > 5 && (
                <Text size="xsmall" color="dark-3" margin={{ left: 'xsmall' }}>
                  + {data.attendees.length - 5} more
                </Text>
              )}
            </Box>
          </Box>
          <Box align="center" justify="center" direction="row" margin={{ bottom: 'large' }}>
            {data.onlineMeetingUrl && (
              <Button
                label="Join Meeting"
                href={data.onlineMeetingUrl}
                target="_blank"
                style={{ background: 'green', color: '#FFF', borderRadius: '48px', fontSize: '12px', padding: '8px 16px 8px 16px' }}
              />
            )}
            <Anchor onClick={onOpen} color="blue" style={{ textDecoration: 'none' }}>
              <Text size="xsmall" margin={{ left: 'medium' }}>Details</Text>
            </Anchor>
          </Box>
        </Box>
      </Grid>
      {open && (
        <Layer id="Event detail overlay" position="center" onClickOutside={onClose} onEsc={onClose}>
          <EventDetail data={data} />
        </Layer>
      )}
    </Box>
  );
};

export default Event;
