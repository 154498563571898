import React from 'react';
import { Text, Anchor, Box, Button } from 'grommet';
import { Clock } from 'grommet-icons';

const TeamSpace = ({ metadata, data }) => {
  return (
    (data && (
      // commented out to always show initial state
      // data.items && (
      false && (
        <Box>
          <Text size="medium">Your Priorities:</Text>
          <Box gap="small" margin={{top: "small", left: "medium"}} align="start">
            {data?.items?.map((item) => {
              return (
                <Anchor key={item.goal_text} color="text-strong" href="https://teamspace.cisco.com/" target="_blank" style={{textDecoration: "none"}}>
                  <Box direction="row" align="center">
                    <Box height="24px" width="24px" round="xxsmall" border={{color: "border", size: "small" }} margin={{right: "small"}}></Box>
                    <Text size="small">{item.goal_text}</Text>
                  </Box>
                </Anchor>
              );
            })}
          </Box>
        </Box>
      ) || (
        <Box align="center" pad={{horizontal: "large"}}>
          <Clock color="orange" size="large" />
          <Text margin={{top: "small"}} size="large" textAlign="center" style={{fontWeight: "300"}}>It’s that time again...</Text>
          <Text margin={{top: "small"}} size="small" color="text-weak" textAlign="center">Let your manager know what you are working on this week.</Text>
          <Button margin={{top: "small"}} primary label="Check In Now" href="https://teamspace.cisco.com/" target="_blank" />
        </Box>
      )
    )) ||
    null
  );
};

export default TeamSpace;
