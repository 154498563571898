import React, { useState, lazy, useEffect } from 'react';
import { Box, Text, Grid, Button, Image, Anchor, Avatar, Layer, Card, CardBody, CardHeader, Accordion, AccordionPanel } from 'grommet';
import { DateTime } from 'luxon';
import parseEvents from './helpers';

const Event = lazy(() => import('./event'));

const M365Events = ({ metadata, data }) => {
  // const [now, setCurrentTime] = useState(DateTime.now());

  // useEffect(() => {
  //   // Update the current time value every second
  //   if (!data.items) return;
  //   const tick = setInterval(() => {
  //     const now = DateTime.now().toUTC();
  //     setCurrentTime(now);
  //   }, 1000);
  //   return () => clearInterval(tick);
  // }, [data]);

  const timeZone = data.timezone;
  const now = DateTime.now().toUTC();

  if (!data.items) return;

  const eventData = parseEvents(data.items, now, timeZone);
  const pastCount = eventData.pastCount;
  const allDayCount = eventData.allDayCount;
  const events = eventData.events;

  return (
    <Box>
      <Box align="baseline" justify="start" direction="row" pad={{ bottom: 'medium', horizontal: 'small' }} border={{ side: 'bottom', color: 'background-back' }}>
        <Text color="green" size="large">
          {DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)}
        </Text>
        <Text size="large" margin={{ left: 'small' }}>
          {DateTime.now().toLocaleString({ weekday: 'long', day: 'numeric', month: 'long' })}
        </Text>
        <Text margin={{ left: 'small' }} size="xsmall">
          ({events.length - pastCount} event{events.length - pastCount > 1 || events.length == 0 ? 's' : ''})
        </Text>
      </Box>
      <Box pad={{ horizontal: 'small', top: 'medium' }}>
        {events.map((event) => {
          if (event.isPast == true) return;
          return <Event key={event.id} data={event} />;
        })}
      </Box>
    </Box>
  );
};

export default M365Events;
