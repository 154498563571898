import { Box, InfiniteScroll, Tab, Tabs, Text } from 'grommet';
import { isEqual } from 'lodash';
import React, { useState } from 'react';

import PlaceholderLoading from '../../components/placeholder-loading/PlaceholderLoading';
import { usePlatformMutation } from '../../hooks';

const VirtualList = ({ items, onMore }) => (
  <InfiniteScroll items={items} onMore={onMore}>
    {(item) => (
      <Box flex direction="row" align="center">
        <Box flex direction="column" gap="small">
          <Text weight={500}>{item.title}</Text>
          <Box direction="row" gap="small">
            <Text>{item.description}</Text>
            <Text>{item.type}</Text>
          </Box>
        </Box>
      </Box>
    )}
  </InfiniteScroll>
);

const MemoizedVirtualList = React.memo(VirtualList, (prevProps, nextProps) => isEqual(prevProps.items, nextProps.items));

const OneDrive = ({ metadata, data }) => {
  const [activeData, setActiveData] = useState(data);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const mutation = usePlatformMutation(`/cardaction/${metadata.Id}`, {
    method: 'POST',
  });

  const onActive = (index) => {
    setLoading(true);
    mutation.mutate(
      {
        Data: {
          args: {
            tab: index,
          },
        },
      },
      {
        onSuccess: (response) => {
          setActiveData(response.Data);
          setActiveIndex(response.Data._tab);
          setLoading(false);
        },
      },
    );
  };

  const onMore = () => {
    // need pagination logic
  };

  return (
    <Box>
      <Tabs activeIndex={activeIndex} onActive={onActive}>
        <Tab title="Recent">{activeIndex === 0 && (loading ? <PlaceholderLoading shape="rect" /> : <MemoizedVirtualList items={activeData.items} />)}</Tab>
        <Tab title="Trending">{activeIndex === 1 && (loading ? <PlaceholderLoading shape="rect" /> : <MemoizedVirtualList items={activeData.items} />)}</Tab>
        <Tab title="Shared">{activeIndex === 2 && (loading ? <PlaceholderLoading shape="rect" /> : <MemoizedVirtualList items={activeData.items} />)}</Tab>
      </Tabs>
    </Box>
  );
};

export default OneDrive;
